import * as React from "react";
import { FunctionComponent, useState } from "react";
import { useForm } from "react-hook-form";
import LoaderIcon from "../../images/icons/loader";
import { orderStatus } from "../../services/salesforce/OrderStatus";
import {
  SanityCtaLabelsInterface,
  SanityErrorMessagesLabelsInterface,
  SanityFormsLabelsInterface
} from "../../types/SanityTypes";
import Button from "../Button";
import Field from "../Forms/Field";
import ReceiptData from "../ReceiptData";
import Section from "../Section";
import "./styles.scss";

export type CheckOrderFormInterface = SanityCtaLabelsInterface &
  SanityErrorMessagesLabelsInterface &
  SanityFormsLabelsInterface;

export const CheckOrderForm: FunctionComponent<CheckOrderFormInterface> = ({ formsLabels, errorMessages }) => {
  console.log("🚀 ~ file: index.tsx:24 ~ formsLabels:", formsLabels);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState(null);
  const formFields: any = [
    [
      {
        type: "text",
        name: "OrderNumber",
        label: formsLabels?.trackOrderNumber,
        placeholder: "123456",
        rules: {
          required: true,
          pattern: {
            value: "^[A-Za-zs]{1,}[.]{0,1}[A-Za-zs]{0,}$"
          }
        },
        message: errorMessages?.validOrderNumber
      },
      {
        type: "email",
        name: "OrderEmail",
        label: formsLabels?.trackOrderEmail,
        placeholder: "user@gmail.com",
        rules: {
          required: true,
          pattern: {
            value: "^[w.%+-]+@[w.-]+.[w]{2,6}$"
          }
        },
        message: errorMessages?.validEmail
      },
      {
        type: "text",
        name: "PostalCode",
        label: formsLabels?.trackOrderPostal,
        placeholder: "123456",
        rules: {
          required: true,
          pattern: "(^d{5}(-d{4})?$)|(^[abceghjklmnprstvxyABCEGHJKLMNPRSTVXY]{1}d{1}[A-Za-z]{1} *d{1}[A-Za-z]{1}d{1}$)"
        },
        message: errorMessages?.validPostalCode
      }
    ]
  ];

  const [orderData, setOrderData] = useState<any>({});
  const [orderStatusMsg, setOrderStatusMsg] = useState<any>("");
  const statusErrMessage = "Sorry, We are not able to find your order details. Please verify the details again.";

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();

  const handleFormSubmit = async (data: any) => {
    try {
      setIsLoading(true);
      const { status: respStatus, data: respData } = await orderStatus(data);

      if (!respData.order) {
        setOrderStatusMsg(statusErrMessage);
        setOrderData({});
      } else {
        setOrderData(respData.order);
        setOrderStatusMsg("");
      }

      if (respStatus === 401) {
        setError(respData.message);
      } else {
        setError(null);
      }
    } catch (err) {
      console.log("error..", err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="layout-container">
      <div className="grid-row">
        <div className="grid-col grid-col-lg-6 grid-col-xs-12">
          <Section title="Fill in Order Details">
            <form onSubmit={handleSubmit(handleFormSubmit)} className="c-signUpForm">
              {formFields.map((field, index) =>
                Array.isArray(field) ? (
                  <div className="row sign-up-form-row" key={index}>
                    <div className="c-field__wrapper col-md">
                      {field.map(field => (
                        <Field
                          className="input-text"
                          key={field.name}
                          {...field}
                          registerInput={register}
                          errors={errors}
                          message={field.message}
                        />
                      ))}
                    </div>
                  </div>
                ) : (
                  <Field className="input-text" key={field.name} {...field} registerInput={register} />
                )
              )}
              <div className="row">
                <div className="col-12">
                  <p className="error-msg col-md">{error}</p>
                </div>
                <div className="col-12">
                  <div className="col-md mt-3">
                    <Button
                      form="br-50"
                      className="w-100 btn-check-order-status"
                      type="submit"
                      variant="nonary"
                      disabled={isLoading}
                    >
                      Check status
                      {isLoading && <LoaderIcon width={13} height={13} className="c-checkOrderForm__loaderIcon" />}
                    </Button>
                  </div>
                </div>
              </div>
            </form>
          </Section>
        </div>
        <div className="grid-col grid-col-lg-6 grid-col-xs-12">
          <Section
            title={"Order Details"}
            description={
              Object.keys(orderData || {}).length > 0 && orderStatusMsg.length == 0 ? (
                <ReceiptData orderData={orderData} />
              ) : (
                <p>{orderStatusMsg.length > 0 ? `${orderStatusMsg}` : "Please fill in your Order Details"}</p>
              )
            }
          ></Section>
        </div>
      </div>
    </div>
  );
};

export default CheckOrderForm;
