import * as React from "react";
import Layout from "../../components/Layout";
import { graphql, PageProps } from "gatsby";
import { LocalisedPageContext } from "../../types/PageTypes";
import SEO from "../../components/Seo";
import {
  LocalizedContextInterface,
  SanitySiteSettings,
  ImageInterface,
  SanityColorList
} from "../../types/SanityTypes";
import { useSiteMetadata } from "../../hooks/useSiteMetadata";
import Helmet from "react-helmet";
import { getLocalizedPath } from "../../utils/gatsbyBuild";
import { withPrefix } from "gatsby";
import HeroBanner from "../../components/HeroBanner";
import CheckOrderForm from "../../components/CheckOrderForm";

export const query = graphql`
  query CheckOrderStatus($_id: String, $language: String) {
    checkOrderStatus: sanityCheckOrderStatus(_id: { eq: $_id }) {
      _id
      banner {
        heading
        subHeading
        primaryColor {
          value
          title
        }
        contentAlignment
        isVideoBanner
        _rawHeroImage(resolveReferences: { maxDepth: 10 })
        heroImage {
          alt
          ...Image
        }
        _rawHeroVideo(resolveReferences: { maxDepth: 10 })
      }
      slug {
        current
      }
    }
    sanitySiteSettings(i18n_lang: { eq: $language }) {
      name
      description
    }
    ...LocalizedContext
  }
`;

type CheckOrderStatusQueryProps = {
  sanitySiteSettings: SanitySiteSettings;
  checkOrderStatus: {
    _id: string;
    banner: {
      heading: string;
      subHeading?: string;
      contentAlignment: string;
      _rawHeroImage: ImageInterface;
      heroImage: ImageInterface;
      _rawHeroVideo: any;
      primaryColor: SanityColorList;
      isVideoBanner: boolean;
    };
    slug: {
      current: string;
    };
  };
} & LocalizedContextInterface;

type LoginPageContext = LocalisedPageContext;

const CheckOrderStatus = (props: PageProps<CheckOrderStatusQueryProps, LoginPageContext>) => {
  const { siteUrl } = useSiteMetadata();
  const site = props.data.sanitySiteSettings;
  const checkOrderStatus = props.data.checkOrderStatus;
  const localizedPath = withPrefix(getLocalizedPath(checkOrderStatus.slug.current, checkOrderStatus._id));

  return (
    <Layout
      localizedContext={{ ...props.pageContext, ...props.data }}
      pageName={checkOrderStatus.banner?.heading}
      pageType={"checkOrderStatus"}
    >
      <Helmet>
        <link rel="canonical" href={`${siteUrl}${localizedPath}`} />
      </Helmet>
      <SEO title={checkOrderStatus.banner?.heading} description={site?.description} />

      <div className="page_checkOrderStatus">
        <HeroBanner
          image={checkOrderStatus.banner?._rawHeroImage}
          sanityImage={checkOrderStatus.banner?.heroImage}
          title={checkOrderStatus.banner?.heading}
          alignment={checkOrderStatus.banner?.contentAlignment}
          isVideoBanner={checkOrderStatus.banner?.isVideoBanner}
          video={checkOrderStatus.banner?._rawHeroVideo}
        />
        <CheckOrderForm
          checkOrderStatus={checkOrderStatus}
          ctaLabels={props.data.sanityLabels?.ctaLabels}
          formsLabels={props.data.sanityLabels?.formsLabels}
          errorMessages={props.data.sanityLabels?.errorMessages}
        />
      </div>
    </Layout>
  );
};

export default CheckOrderStatus;
